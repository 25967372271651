.indexPage{
    .banner{
        width: 100vw;
        height: 100vh;
        position: relative;
        background: url(../images/banner_bg.jpg) no-repeat center center / cover;
        @media (max-width:767px) and (max-height:700px) {
            overflow: hidden;
            height: 100vh;
            background: url(../images/banner_mb_bg.jpg) no-repeat center center / cover;
        }
        @media (max-width:767px) and (min-height:701px) {
            overflow: hidden;
            height: 92vh;
            background: url(../images/banner_mb_bg.jpg) no-repeat center center / cover;
        }
        // @media (max-width:767px) and (min-height:761px) {
        //     overflow: hidden;
        //     // height: 50vh;
        //     background: url(../images/banner_mb_bg.jpg) no-repeat center center / cover;
        // }
        .img-obx{
            height: 100%;

            @media (max-width:991px) {
                padding-top: 5%;
                position: relative;
            }
            @media (max-width:480px){
                padding-top: 5%;
            }
            .l{
                width: 40%;
                text-align: right;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                background: url(../images/banner_tx.png) no-repeat center right / contain;
                height: 81.7vh;
                margin-left: 5%;
                z-index: 2;
                @media (max-width:991px) {
                    top: auto;
                    transform: none;
                    width: 50%;
                    height: 0;
                    margin: 0 auto;
                    padding-bottom: 58%;
                    background-size: cover;
                    background-position: center center;
                }
                @media (max-width:480px) {
                    background: url(../images/banner_mb_tx.png) no-repeat top center / contain;
                    padding-bottom: 0%;
                    height: 50vh;
                    width: 75%;
                    z-index: 5;
                }
                img{
                
                }
            }
            .r-img{
                position: absolute;
                right: 0;
                bottom: 0;
                width: 54%;
                text-align: right;
                z-index: 2;
                @media (max-width:991px) {
                    // position: relative;
                    width: 60%;
                    margin-left: auto;
                }
                @media (max-width:767px) {
                    position: absolute;
                    bottom: 0%;
                    margin-top: 5%;
                    width: 70%;
                    margin-left: auto;
                }
                @media (max-width:480px) {
                    width: 92%;
                }
                @media (max-width:767px) and (max-height:700px) {
                    bottom: -6%;
                }
                @media (max-width:767px) and (min-height:701px) {
                    bottom: 0%;
                }
            }
        }
        .ball{
            animation: fade 3s linear infinite;
        }
        .rball{
            width: 142px;
            height: 142px;
            background-color: #e84720;
            position: absolute;
            top: 30px;
            right: -25px;
            @media (max-width:767px) {
                width: 45px;
                height: 45px;
                top: 58%;
                right: -8px;
                transform: translateY(-50%);
            }
        }
        .wball{
            background-color: #ffffff;
            width: 35px;
            height: 35px;
            position: absolute;
            top: 170px;
            right: 160px;
            animation-delay: .7s;
            @media (max-width:767px) {
               width: 12px;
               height: 12px;
               top: 62%;
               right: 45px;
               transform: translateY(-50%);
            }
        }
        .yball{
            background-color: #fbc70d;
            width: 80px;
            height: 80px;
            position: absolute;
            top: 15px;
            right: 210px;
            z-index: 1;
            animation-delay: .3s;
            @media (max-width:767px) {
                width: 25px;
                height: 25px;
                top: 55%;
                right: 55px;
                transform: translateY(-50%);
            }
        }
        .brball{
            background-color: #e84720;
            width: 84px;
            height: 84px;
            position: absolute;
            bottom: 65px;
            left: 90px;
            z-index: 1;
            animation-delay: .2s;
            @media (max-width:767px) {
                display: none;
            }
        }
        .bwball{
            background-color: #ffffff;
            width: 37px;
            height: 37px;
            position: absolute;
            bottom: 170px;
            left: -12px;
            z-index: 1;
            animation-delay: .5s;
            @media (max-width:767px) {
                display: none;
            }
        }
    }
    .item1{
        position: relative;
        padding: 80px 0;
        background-image: linear-gradient(to top, #0066ad, #004f8c);
        @media (max-width:991px) {
            padding: 40px 0;
        }
        .it1-bg{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 4;
        }
        .container{
            @media (min-width:1199px) {
                max-width: 1264px;
            }
        }
        .it1-bx{
            padding-top: 75px;
            padding-bottom: 80px;
            background-color: #ffffff;
            border-radius: 20px;
            border: solid 4px #dfb881;
            z-index: 5;
            position: relative;
            @media (max-width:991px) {
                padding-top: 35px;
                padding-bottom: 40px;
                border-radius: 10px;
            }
            .ti{
                margin-bottom: 60px;
                @media (max-width:991px) {
                    margin-bottom: 30px;
                }
            }
            ul{
                display: flex;
                justify-content: space-between;
                width: 88%;
                margin: 0 auto;
                @media (max-width:991px) {
                    display: block;
                }
                li{
                    width: 27.2%;
                    text-align: center;
                    &:nth-child(2){
                        width: 27.5%;
                        @media (max-width:991px) {
                            width: 100%;
                        }
                    }
                    @media (max-width:991px) {
                        width: 100%;
                        &:not(:last-child){
                            margin-bottom: 20px;
                        }
                    }
                    .ti{
                        @include fontset(34,1.1,0.8,700,#0066ad);
                        padding-bottom: 12px;
                        position: relative;
                        margin: 30px 0;
                        display: inline-block;
                        @media (max-width:991px) {
                            font-size: pxToEm(19);
                            padding-bottom: 6px;
                            margin: 15px 0;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #0066ad;
                        }
                    }
                    .des{
                        @include fontset(24,1.6,0.6);
                        @media (max-width:991px) {
                            font-size: pxToEm(16);
                        }
                    }
                }
            }
        }
    }
    .item2{
        padding-top: 115px;
        padding-bottom: 80px;
        background-color: rgba(#8cc9f3,.2);
        @media (max-width:991px) {
            padding-top: 65px;
            padding-bottom: 40px;
        }
        .it2-bx{
            .des{
                text-align: center;
                margin-top: 65px;
                @include fontset(24,1.6,.6);
                @media (max-width:991px) {
                    margin-top: 30px;
                    font-size: pxToEm(16);
                }
            }
        }
    }
    .item3{
        // padding-bottom: 47.3%;
        // background: url(../images/02_map.jpg) no-repeat center center / cover;
        position: relative;
        @media (max-width:991px) {
            margin-top: 0;
        }
        &::before{
            height: 0;
        }
        @media (max-width:750px) {
            height: 351px;
        }
        .m-img{
            position: relative;
            @media (max-width:750px) {
              
                .mapimg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    min-width: 750px;
                    height: 351px;
                }
            }
            @media (max-width:450px) {
                animation: mapani 10s linear infinite;
            }
        }
        @keyframes mapani {
            0%{
                transform: translateX(0);
            }
            50%{
                transform: translateX(-50%);
            }
            100%{
                transform: translateX(0);
            }
        }
        .logoani{
            position: absolute;
            top: 5%;
            left: 39.2%;
            transform: translateX(-50%);
            width: 4.7%;
            animation: logofade 2s linear infinite;
            @media (max-width:750px) {
                top: 22px;
                left: 294px;
                width: 35px;
            }
            @keyframes logofade {
                0%{
                    opacity: .3;
                }
                50%{
                    opacity: 1;
                }
                100%{
                    opacity: .3;
                }
            }
        }
    }
    .item4{
        padding: 120px 0 ;
        position: relative;
        @media (max-width:991px) {
            padding: 60px 0;
        }
        .container{
            margin-right: 0;
            margin-left: auto;
            padding-right: 0;
            @media (min-width:1199px) {
                max-width: 1488px;
            }
            @media (max-width:1198px) and (min-width:992px) {
                max-width: 100%;
            }
            @media (max-width:991px) {
                margin: 0 auto;
                padding-right: 25px;
            }
        }
        .it4-bx{
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width:991px) {
                display: block;
            }
        }
        .l{
            max-width: 452px;
            width: 100%;
            margin-right: 5.7%;
            @media (max-width:991px) {
                margin-right: 0;
                max-width: 100%;
            }
            .b-blueline{
                text-align: left;
                @media (max-width:991px) {
                    text-align: center;
                }
                &:nth-child(1){
                    margin-bottom: 20px;
                }
                &:nth-child(2){
                    margin-bottom: 65px;
                    @media (max-width:991px) {
                        margin-bottom: 32px;
                    }
                }
            }
            .ti{
            }
            .des{
                @include fontset(24,1.67,.6);
                @media (max-width:991px) {
                    margin-bottom: 40px;
                    font-size: pxToEm(17);
                }
            }
        }
        .r-bx{
            // width: 63.5%;
            width: calc(94.3% - 452px);
            @media (max-width:991px) {
                width: 100%;
            }
            ul{
                li{
                    width: 608px;
                    margin-right: 15px;
                    @media (max-width:991px) {
                        margin-right: 0;
                    }
                }
                li .bgcover{
                    background-size: cover !important;
                    padding-bottom: 108%;
                }
            }
        }
        .a_bg{
            position: absolute;
            top: -125px;
            left: 300px;
            z-index: -1;
            @media (max-width:991px) {
                top: -50px;
                left: 35px;
                width: 100px;
            }
        }
        .b_bg{
            position: absolute;
            left: 27px;
            bottom: 58px;
            z-index: -1;
            @media (max-width:991px) {
                bottom: 0;
                width: 110px;
            }
        }
        .c_bg{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: -2;
            width: 493px;
            animation: fade 3s linear infinite;
            @media (max-width:991px) {
                width: 200px;
            }
        }
    }
    .item5{
        padding: 100px 0;
        background-color: #0066ad;
        @media (max-width:991px) {
            padding: 50px 0;
        }
        .tx-bx{
            padding: 0 16px;
            margin-bottom: 80px;
            @media (max-width:991px) {
                margin-bottom: 40px;
            }
        }
        .b-blueline{
            margin-bottom: 65px;
            @media (max-width:991px) {
                margin-bottom: 30px;
            }  
        }
        .b-blueline .ti{
            color: #ffffff;
        }
        .des{
            max-width: 880px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            @include fontset(24,1.67,.6,400,#ffffff);
            @media (max-width:991px) {
               font-size: pxToEm(17);
            } 
        }
        #scroller{
            display: flex;
            li{
                min-width: 504px;
                height: 330px;
                margin-left: 15px;
                @media (max-width:991px) {
                    min-width: 252px;  
                    height: 165px;
                }
                img{
                    width: 504px;
                    height: 330px;
                    @media (max-width:991px) {
                        width: 252px;  
                        height: 165px;
                    }
                }
            }
        }
        .simply-scroll,.simply-scroll-clip{
            width: 100%;
            height: 330px;
            @media (max-width:991px) {
                height: 165px;
            }
        }
    }
    .item6{
        padding-top: 95px;
        padding-bottom: 120px;
        background-color: rgba(#8cc9f3,.2);
        @media (max-width:991px) {
            padding-top: 45px;
            padding-bottom: 60px;
        }
        .tx-bx{
            padding: 0 16px;
            margin-bottom: 80px;
            text-align: center;
            @media (max-width:991px) {
                margin-bottom: 40px;
            }
            .des{
                max-width: 900px;
                width: 100%;
                @include fontset(24,1.67,.6);
                margin: 0 auto;
                @media (max-width:991px) {
                    font-size: pxToEm(17);
                }
            }
        }
        .b-blueline{
            margin-bottom: 65px;
            @media (max-width:991px) {
                margin-bottom: 30px;
            }
        }
        .container{
           
        }
        .it6-bx{
            .l{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 804px;
                padding-top: 80px;
                padding-bottom: 120px;
                padding-right: 70px;
                background-color: #0066ad;
                overflow: hidden;
                z-index: 9;
                @media (max-width:1300px) {
                    width: 600px;
                }
                @media (max-width:991px) {
                    position: relative;
                    width: 100%;
                    padding-right: 0;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: -206px;
                    bottom: -164px;
                    width: 80%;
                    height: 538px;
                    background: url(../images/05_bg01.png) no-repeat center center / contain;
                    @media (max-width:991px) {
                        left: -123px;
                        bottom: -200px;
                        height: 460px;
                    }
                }
                .slick-list{
                    width: 279px;
                    margin-left: auto;
                    @media (max-width:991px) {
                        margin: 0 auto;
                    }
                }
                .slick-prev{
                    left: auto;
                    right: 200px;
                    top: auto;
                    transform: none;
                    bottom: 50px;
                    width: 40px;
                    height: 40px;
                    @media (max-width:991px) {
                        right: 160px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        border-right: 14px solid #fff;
                        border-top: 9px dashed transparent;
                        border-bottom: 9px dashed transparent;
                        transition: .4s;
                    }
                    @media (min-width:991px) {
                        &:hover{
                            &::before{
                                border-right: 14px solid #feda26;
                                border-top: 9px dashed transparent;
                                border-bottom: 9px dashed transparent;
                            }
                        }
                    }
                }
                .slick-next{
                    left: auto;
                    right: 60px;
                    @media (max-width:991px) {
                        right: 20px;
                    }
                    top: auto;
                    transform: none;
                    bottom: 50px;
                    width: 40px;
                    height: 40px;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        border-left: 14px solid #fff;
                        border-top: 9px dashed transparent;
                        border-bottom: 9px dashed transparent;
                        transition: .4s;
                    }
                    @media (min-width:991px) {
                        &:hover{
                            &::before{
                                border-left: 14px solid #feda26;
                                border-top: 9px dashed transparent;
                                border-bottom: 9px dashed transparent;
                            }
                        }
                    }
                }
                .slick-dots {
                    position: absolute;
                    display: flex;
                    width: 87px;
                    padding: 0;
                    margin: 0;
                    bottom: 60px;
                    right: 115px;
                    
                    li{
                        display: none;
                        width: 30px;
                        height: 30px !important;
                        &:first-child{
                            display: block;
                        }
                        &:last-child{
                            display: block;
                        }
                    }
                }
                .slick-dots li button{
                    @include fontset(24,1.25,.6,700,#ffffff);
                    width: 30px;
                    padding: 0;
                    &::before{
                        width: 0;
                    }
                }
                .des-bxlist{
                    position: static;
                }
                .dots-bx{
                    position: absolute;
                    right: 110px;
                    bottom: 56px;
                    display: flex;
                    align-items: center;
                    @media (max-width:991px) {
                        right: 70px;
                    }
                    span{
                        width: 30px;
                        display: inline-block;
                        i{
                            color: #feda26;
                        }
                    }
                    span{
                        @include fontset(24,1.25,.6,700,#ffffff);
                        position: relative;
                        &:first-child{
                            padding-right: 10px;
                            margin-right: 10px;
                            width: 40px;
                            &::before{
                                position: absolute;
                                content: '';
                                right: 0;
                                top: 8px;
                                height: 60%;
                                width: 1px;
                                background-color: #ffffff;
                            }
                        }
                    }
                }
                .des-bx{
                    max-width: 279px;
                    width: 100%;
                    margin-left: auto;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    .ti{
                        @include fontset(34,1.18,.85,700,#ffffff);
                        padding-bottom: 7px;
                        margin-bottom: 25px;
                        position: relative;
                        @media (max-width:991px) {
                            font-size: pxToEm(20);
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #ffffff;
                        }
                    }
                    .des{
                        @include fontset(24,1.67,.6,400,#ffffff);
                        text-align: justify;
                        @media (max-width:991px) {
                            font-size: pxToEm(16);
                        }
                    }
                }
            }
            .r{
                margin-left: auto;
                width: 58.3%;
                @media (max-width:991px) {
                    width: 100%;
                }
                ul li{
                    .bgcover{
                        padding-bottom: 604px;
                        @media (max-width:991px) {
                            padding-bottom: 65.5%;
                            background-size: cover !important;
                        }
                    }
                }
            }
        }
        .container{
            margin-right: 0;
            margin-left: 0;
            position: relative;
            padding-left: 0;
            @media (min-width:1199px) {
                max-width: 1595px;
            }
            @media (max-width:1198px) and (min-width:992px) {
                max-width: 100%;
            }
            @media (max-width:991px) {
                margin: 0 auto;
                padding-left: 16px;
            }
        }
    }
    .item7,.item8-1{
        padding: 120px 0;
        background-color: #0066ad;
        position: relative;
        overflow: hidden;
        @media (max-width:991px) {
            padding: 60px 0 ;
        }
        .lball{
            position: absolute;
            top: 95px;
            left: 0;
            z-index: 2;
            @media (max-width:991px) {
                width: 60px;
                top: 40px;
            }
        }
        .rball{
            position: absolute;
            right: 0;
            top: 70px;
            z-index: 2;
            @media (max-width:991px) {
                width: 111px;
                top: 35px;
            }
        }
        .btree{
            position: absolute;
            bottom: -200px;
            left: 0;
            @media (max-width:991px) {
                width: 130px;
                bottom: -100px;
            }
        }
        .tx-bx{
            padding: 0 16px;
            margin-bottom: 80px;
            position: relative;
            z-index: 5;
            @media (max-width:991px) {
                margin-bottom: 25px;
            }
        }
        .b-blueline{
            margin-bottom: 65px;
            @media (max-width:991px) {
                margin-bottom: 30px;
            }
        }
        .b-blueline .ti{
            color: #ffffff;
        }
        .des{
            max-width: 880px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            @include fontset(24,1.67,.6,400,#ffffff);
            @media (max-width:991px) {
                font-size: pxToEm(17);
            }
        }
        .container{
            @media (min-width:1199px) {
                max-width: 1682px;
            }
            @media (max-width:1198px) and (min-width:768px) {
                max-width: 100%;
            }
        }
        .it7-bx{
            ul{
                li{
                    margin: 0 9px;
                    transition: .4s !important;
                    transition-delay: 1.5s;
                    transform: scale(.9);
                    .bgcover{
                        padding-bottom: 65.4%;
                        transition: .4s ease;
                        transform-origin: center;
                    }
                }
                .slick-current{
                    img{
                    }
                }
                .slick-current+.slick-active{
                    .bgcover{
                        transform: scale(1.13);
                        transition-delay: .8s;
                    }
                }
                
               .slick-track{
                }
            }
        }
    }
    .item8,.item8-2{
        background-color: rgba(#8cc9f3,.2);
        position: relative;
        @media (max-width:991px) {
            padding: 60px 0 ;
        }
        .ball{
            position: absolute;
            border-radius: 50%;
            opacity: 0;
        }
        .ball5{
            width: 54px;
            height: 54px;
            background-color: #ffda2a;
            right: -12px;
            top: -125px;
            animation: fade 3s 2s linear infinite;
            @media (max-width:991px) {
                top: -50px;
                width: 27px;
                height: 27px;
                right: 50px;
            }
        }
        .ball6{
            width: 147px;
            height: 147px;
            background-color: #d92724;
            right: -258px;
            top: -110px;
            animation: fade 4s 2s linear infinite;
            @media (max-width:991px) {
                top: -55px;
                right: -40px;
                width: 73px;
                height: 73px;
            }
        }
        .ball7{
            width: 29px;
            height: 29px;
            background-color: #0066ad;
            right: -238px;
            top: 160px;
            animation: fade 3s 2s linear infinite;
            @media (max-width:991px) {
                top: 80px;
                right: -30px;
                width: 15px;
                height: 15px;
            }
        }
        .ball8{
            width: 67px;
            height: 67px;
            background-color: #ffda2a;
            right: -315px;
            top: 247px;
            animation: fade 3s 1s linear infinite;
            @media (max-width:991px) {
                top: 120px;
                right: -60px;
                width: 33px;
                height: 33px;
            }
        }
        .container{
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 16px;
            @media (min-width:1199px) {
                max-width: 1488px;
            }
            @media (max-width:1198px) and (min-width:992px) {
                max-width: 100%;
            }
            @media (max-width:991px) {
                margin: 0 auto;
                padding-left: 16px;
            }
        }
        .it8-bx{
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width:991px) {
                display: block;
            }
        }
        .l{
            max-width: 452px;
            width: 100%;
            margin-right: 5.7%;
            position: relative;
            @media (max-width:991px) {
                max-width: 100%;
                margin-right: 0;

            }
            .b-blueline{
                text-align: left;
                &:nth-child(1){
                    margin-bottom: 20px;
                }
                &:nth-child(2){
                    margin-bottom: 65px;
                    @media (max-width:991px) {
                        margin-bottom: 30px;
                    }
                }
                @media (max-width:991px) {
                    text-align: center;
                }
            }
            .ti{
            }
            .des{
                @include fontset(24,1.67,.6);
                @media (max-width:991px) {
                    text-align: center;
                    font-size: pxToEm(17);
                    margin-bottom: 30px;
                }
            }
        }
        .r-bx{
            width: calc(94.3% - 452px);
            background-color: #ffffff;
            position: relative;
            @media (max-width:991px) {
                width: 100%;
            }
            &::before{
                position: absolute;
                content: '';
                right: 0;
                top: 0;
                width: 15px;
                height: 100%;
                background-color: rgba(#8cc9f3,.2);
            }
            ul{
                li{
                    // width: 608px;
                    margin-right: 15px;
                    @media (max-width:991px) {
                        margin-right: 0;
                    }
                }
                li .bgcover{
                    background-size: cover !important;
                    padding-bottom: 656px;
                    @media (max-width:991px) {
                        padding-bottom: 108%;
                    }
                }
            }
        }
       
        .it8-bx{
            .l{
                order: 2;
                margin-left: 5.7%;
                margin-right: 0;
                @media (max-width:991px) {
                    margin-left: 0;
                }
            }
            .r-bx{
                order: 1;
            }
        }
    }
    .succ{
        padding: 120PX 0 ;
        position: relative;
        overflow: hidden;
        @media (max-width:991px) {
            padding: 60px 0 ;
            padding-bottom: 100px;
        }
        .bg{
            z-index: -1;
        }
        .bg1{
            position: absolute;
            top: 115px;
            left: -205px;
            @media (max-width:991px) {
                top: 50px;
                left: -77px;
                width: 140px;
            }
        }
        .bg2{
            position: absolute;
            top: 520px;
            right: -50px;
            @media (max-width:991px) {
                width: 89px;
                right: -77px;
            }
        }
        .bg3{
            position: absolute;
            bottom: 190px;
            left: -205px;
            @media (max-width:991px) {
                left: -79px;
                width: 100px;
            }
        }
        .bg4{
            position: absolute;
            bottom: -320px;
            left: -85px;
            width: 464px;
            height: 517px;
            @media (max-width:991px) {
                width: 232px;
                bottom: -460px;
            }
        }
        .bg5{
            position: absolute;
            right: 20px;
            bottom: -30px;
            @media (max-width:991px) {
                width: 140px;
            }
        }
        .bg6{
            position: absolute;
            bottom: -180px;
            right: 0px;
            @media (max-width:991px) {
                width: 100px;
                bottom: -125px;
            }
        }

        .ball{
            position: absolute;
            z-index: -1;
            border-radius: 50%;
            @media (max-width:991px) {
                width: 0;
            }
        }
        .ball1{
            width: 84px;
            height: 84px;
            background-color: #0066ad;
            top: 35%;
            left: 82px;
            transform: translateY(-50%);
            @media (max-width:991px) {
                width: 0;
            }
        }
        .ball2{
            width: 148px;
            height: 148px;
            background-color: #d92724;
            top: 42%;
            transform: translateY(-50%);
            left: 166px;
            @media (max-width:991px) {
                width: 0;
            }
        }
        .ball3{
            width: 190px;
            height: 190px;
            background-color: #ffda2a;
            top: 52%;
            transform: translateY(-50%);
            left: -65px;
            @media (max-width:991px) {
                width: 0;
            }
        }
        .ball4{
            width: 119px;
            height: 119px;
            background-color: #0066ad;
            right: 100px;
            top: 55%;
            transform: translateY(-50%);
            @media (max-width:991px) {
                width: 0;
            }
        }
        .ball5{
            height: 246px;
            width: 246px;
            background-color: #d92724;
            right: -100px;
            top: 63%;
            transform: translateY(-50%);
            @media (max-width:991px) {
                width: 0;
            }
        }
        .tx-bx{
            padding: 0 16px;
            margin-bottom: 80px;
            position: relative;
            z-index: 5;
            @media (max-width:991px) {
                margin-bottom: 40px;
            }
        }
        .b-blueline{
            margin-bottom: 65px;
            @media (max-width:991px) {
                margin-bottom: 35px;
            }
        }
        .b-blueline .ti{
        }
        .des{
            max-width: 880px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            @include fontset(24,1.67,.6,400,#000000);
            @media (max-width:991px) {
                font-size: pxToEm(17);
            }
        }
        .container{
            @media (min-width:1199px) {
                max-width: 1056px;
            }
        }
        .succ-bx{
            .flex-bx{
                display: flex;  
                justify-content: space-between;   
                @media (max-width:991px) {
                    display: block;
                } 
                .l{
                   margin-right: 2%;
                   @media (max-width:991px) {
                       margin-right: 0;
                       text-align: center;
                       margin-bottom: 30px;
                       img{
                           width: 100%;
                       }
                   }
                }  
                .r{
                    @media (max-width:991px) {
                        text-align: center;
                        margin-bottom: 30px;
                        img{
                            width: 100%;
                        }
                    }
                }
                &:nth-child(1){
                    margin-bottom: 30px;
                    @media (max-width:991px) {
                        margin-bottom: 15px;
                    }
                }       
                &:nth-child(2){
                    margin-bottom: 40px;
                    @media (max-width:991px) {
                        margin-bottom: 20px;
                    }
                    .r{
                        order: 2;
                    }
                    .l{
                        order: 1;
                    }
                } 
            }
        }
    }
    .item9{
        background-color: $hover;
        padding-top: 120px;
        padding-bottom: 120px;
        z-index: 50;
        position: relative;
        overflow: hidden;
        &::before{
            position: absolute;
            content: '';
            background: url(../images/kv_bg.png) no-repeat center/contain;
            width: 100%;
            height: 580px;
            bottom: -220px;
            left: 0;
        }
        @media (max-width:991px){
            padding-top: 90px;
            padding-bottom: 55px;
        }
        .it9-bx{
            background-color: #ffffff;
            padding: 75px 100px 80px 100px;
            // border: solid 4px #dfb881;
            // background-repeat: no-repeat;
            // background-origin: padding-box, border-box;
            border-radius: 10px;
            position: relative;
            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background:#ffffff;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                border-radius: 10px;
            }
            &::after{
                position: absolute;
                content: '';
                width: calc(100% + 8px);
                height: calc(100% + 8px);
                background:linear-gradient(to top, #f5c98a, #db9c58);
                top:-4px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
                border-radius: 10px;
            }
            @media (max-width:1199px) {
                padding: 70px 80px 70px;
            }
            // @media (max-width:991px) {
            //     padding: 75px 50px 70px;
            // }
            @media (max-width:767px) {
                padding: 40px 25px 40px;
            }
            @keyframes fade {
                0%{
                    opacity: 0;
                }
                50%{
                    opacity: 1;
                }
                100%{
                    opacity: 0;
                }
            }
           
            .ball{
                position: absolute;
                border-radius: 50%;
                opacity: 0;
                z-index: -1;
            }
            .ball1{
                width: 45px;
                height: 45px;
                background-color: #ffffff;
                left: -17px;
                top: -71px;
                animation: fade 3s 1s linear infinite;
            }
            .ball2{
                width: 134px;
                height: 134px;
                background-color: #ffda2a;
                left: -220px;
                top: 0px;
                animation: fade 4s 2s linear infinite;
            }
            .ball3{
                width: 60px;
                height: 60px;
                background-color: #d92724;
                left: -41px;
                top: 171px;
                animation: fade 3s 2s linear infinite;
            }
            .ball4{
                width: 71px;
                height: 71px;
                background-color: #ffffff;
                left: -188px;
                top: 274px;
                animation: fade 3s 1s linear infinite;
            }
            .ball5{
                width: 54px;
                height: 54px;
                background-color: #ffda2a;
                right: 120px;
                top: -45px;
                animation: fade 3s 2s linear infinite;
            }
            .ball6{
                width: 147px;
                height: 147px;
                background-color: #d92724;
                right: -109px;
                top: -30px;
                animation: fade 4s 2s linear infinite;
            }
            .ball7{
                width: 29px;
                height: 29px;
                background-color: #ffffff;
                right: -90px;
                top: 235px;
                animation: fade 3s 2s linear infinite;
            }
            .ball8{
                width: 67px;
                height: 67px;
                background-color: #ffda2a;
                right: -180px;
                top: 323px;
                animation: fade 3s 1s linear infinite;
            }
            .fortit-img{
                text-align: center;
                margin-bottom: 80px;
                position: relative;
                z-index: 10;
                @media (max-width:767px) {
                    margin-bottom: 40px;
                }
            }
        }
        .tit{
            text-align: center;
            @include fontset(24,1.9,0,400,#666666);
            margin-bottom: 50px;
            position: relative;
            z-index: 10;
            @media (max-width:991px) {
                font-size: pxToEm(18);
                margin-bottom: 25px;
            }
        }

        // 表單部分
        .form_des{
            color: #000000;
            text-align: center;
            font-size: pxToRem(18);
            margin-bottom: 1.125em;
            line-height: 1.5;
           
            
        }
        form{
            // background:repeat-y url(../images/9/form-repeat.png) center / contain;
            overflow: hidden; 
            letter-spacing: 0.01em;
            position: relative;
            z-index: 10;
            .form-bx{
                display: flex;
                justify-content: space-between;
                .form-group{
                    margin-bottom: 8px;
                    width:calc(50% - .5%);
                    @media (max-width:991px) {
                        width: 100%;
                    }
                }
                @media (max-width:991px) {
                    display: block;
                }
            }
            .c-bx,.d-bx{
                width:calc(50% - .5%);
                @media (max-width:991px) {
                    width: 100%;
                }
            }
            input,select,textarea{
                outline: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                box-shadow:none;
                width:100%;
                background-color:#ddeffb;
                font-size: pxToRem(18);
                color: #000000;
                border-radius: 0;
                &:focus { outline: none; }
            }
            input,select{
                height:61px;
                padding:0px 8px;
                border:none;
                // @include placeholder {
                //     font-size: pxToRem(16);
                //     color: #000000;
                //     letter-spacing: 0.01em;
                // }
                @media (max-width:991px) {
                    height: 45px;
                }
            }
            //select
            select::-ms-expand { display: none; }    
            select{
                background:url('../images/sel-ar.png') #e8e8e8 no-repeat calc(100% - 20px) 55% / 12px;
                padding:3px 15px 3px 8px ;
                outline: none;
                background-color:#ddeffb;
                color: #000000;
            }
            #twzipcode.nonsel{
                select{
                    color: #787878;
                }
            }
          
            label{
                line-height: 1.43;
                margin-bottom: 10px;
                letter-spacing: 0;
                color: #000000;
                font-size: pxToEm(18);
                display: block;
                @media (max-width:991px) {
                    font-size: pxToEm(16);
                }
                span{
                    color: #0066ad;
                }
            }
            .with-errors{
                flex:0 0 100%;
                min-height:22px;
                line-height:1.43;
                letter-spacing: 0px;
                color: #0066ad;
                display:block;
                margin-top: 8px;
                margin-bottom: 30px;
                font-size: pxToEm(16);
                @media (max-width:991px) {
                    font-size: pxToEm(16);
                    margin-bottom: 15px;
                }
            }
            .send-bx{
                text-align: center;
            }
            a[type="submit"]{
                outline: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                box-shadow:none;
                line-height: 40px;
                height: 60px;
                width:270px;
                text-align: center;
                color: #ffffff;
                letter-spacing: 1px;
                font-size: pxToEm(20);
                margin-top: 15px;
                display: inline-block;
                background-color: #0066ad;
                position: relative;
                opacity: 1;
                border: solid 2px #0066ad;
                transition: .4s;
                border-radius: 5px;
                &:hover{
                    text-decoration: none;
                }
                span{
                    position: relative;
                    @include fontset(22,2.6,0,400,#ffffff);
                    @media (max-width:991px) {
                        font-size:pxToEm(20);
                        line-height: 1.4;
                    }
                }
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width:100%;
                    // background:no-repeat url(../images/9/form-btn.png) center / cover;
                    // @include gradient-animation(#b89109,#ffee9d);
                    background-size: 200% 200%;   
                    animation:gradeffect infinite 1s linear;   
                }
                @media (min-width:768px) {
                    &:hover{
                        background-color: #ffffff;
                        span{
                            color: #0066ad;
                        }
                    }
                }
                @media (max-width:991px) {
                    width: 180px;
                    height: 45px;
                }
            }
            //agree
            .agree{
                text-align: center;
            }
            .agr-des{
                text-align: center;
                @include fontset(16,1.63,0,400,#000000);
                margin-top: 10px;
                margin-bottom: 45px;
                @media (max-width:991px) {
                    font-size: pxToEm(13);
                    margin-top: 20px;
                    margin-bottom: 25px;
                }
            }
            .check_radi {
                padding-left: 45px;
                height: 32px;
                text-align: left;
                width: auto;
                position: relative;
                cursor: pointer;
                user-select: none;
                display: inline-block;
                @media (max-width:991px) {
                    padding-left: 45px;
                }
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    left: 0;
                    top:0;
                    z-index: 5;
                    width:24px;
                    height: 24px;
                }
                .checkmark {
                    position: absolute;
                    top: -3px;
                    left: 0;
                    height: 32px;
                    width: 32px;
                    background-color: #ddeffb;
                    @media (max-width:991px) {
                        top: -4px;
                    }
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 7px;
                    display: none;
                    width: 26px;
                    height: 22px;
                    background: url('../images/03_check.png') no-repeat center / contain ;
                }
                input:checked~.checkmark:after {
                    display: block;
                }
                a[data-popup]{
                    color:#000000;
                    position: relative;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            .form-textbx{
                .textarea-wrapper{
                    height: 250px;
                    overflow: hidden;
                    border:none;
                    padding:0px 0px 10px;
                    margin-top: 10px;
                    background-color: rgba(#8cc9f3,.3);
                    @media (max-width:767px) {
                        height: 210px;
                    }
                    textarea{
                        min-height: 239px;
                        height: 0;
                        overflow: hidden;
                        border-bottom:none;
                        padding-top:14px;
                        padding-left: 11px;
                        text-rendering: auto;
                        -webkit-writing-mode: horizontal-tb !important;
                        resize: none;
                        border: none;
                        font-family: $font_content;
                        cursor:text;
                        @media (max-width:767px) {
                            min-height: 194px;
                        }
                        &:focus{
                                    border: none ;
                                }
                    }
                }
                .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                    background-color: #000000;
                    width: 2px;
                }
                .mCSB_scrollTools .mCSB_draggerRail{
                    background-color: #0066ad;
                    width: 2px;
                }
                .mCSB_scrollTools{
                    right: -5px;
                }
                .mCSB_inside>.mCSB_container{
                    margin-right: 13px;
                }
                .mCSB_scrollTools .mCSB_draggerContainer{
                    top: 15px;
                    left: -12px;
                }
            }
        }

      
    }
    .overbg{
        // display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .5s linear;
        z-index: 1012;
        background-color: rgba(0,0,0,.8);
        pointer-events: none;
    }
    .modal-content{
        max-width: 767px;
        max-height: 600px;
        width:90%;
        height: 95%;
        padding:40px;
        position: fixed;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        margin-left: -7px;    
        z-index: 999;
        display: none;
        @media (max-width:640px){
            padding: 50px 2.5%;
            margin-left: 0px; 
        }
        .modalBox{
            width: 100%;
            height:100%;
            text-align:left;
            -webkit-overflow-scrolling:touch;
            background-color: #fff;        
            padding: 25px 20px;
            @include border(10px);
            font-size: pxToEm(12);
            color: #666;
            @media (max-width:640px){
                padding:20px 10px 20px 15px;
            }
        }
        #telbox{
            text-align: center;
            display: none;
            align-items: center;
            justify-content: center;
            height: 100%;
            color:black;
            a[href^="tel:"]{
                max-width:450px;
                width: 80%;            
                color:white;
                background-color: #0066ad;
                display: block;
                margin:35px auto 0;
                padding: 15px 0;
            }
            > div{
                flex:0 0 100%;
            }
            .txt{
                font-size: pxToEm(20);
                margin:10px 0 0;
            }
        }
        
        &.telbox{
            height: 400px;
            #telbox{
                display: flex;
            }
        }
        &.agreebox .text-justify{
            display: block;
        }
        .text-justify{
            display: none;
            overflow-y:auto; 
            width: 100%;
            height:100%;
            padding-right: 10px;
            line-height: 1.5;
            text-align: initial;
            @media (max-width:640px){
                padding-right: 5px;
            }
        }
        .close{
            cursor: pointer;
            // border:2px solid #909399;
            @include border();
            z-index: 99993;
            position: absolute;
            display: block;
            width:40px;
            height: 40px;
            top:0px;
            right:0px;
            &:before, &:after {
                position: absolute;
                left: 18px;
                top:4px;
                content: ' ';
                height: 30px;
                width: 2px;
                background-color: #909399;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            @media(max-width: 640px){
                right:2.5%;
            }
        }
        //內容樣式
        h4{
            text-align: center;
            font-size: pxToEm(32);
            color:#303133;
            margin-bottom: 36px;
        }
        h6{
            font-size: pxToEm(24);
            color: #000000;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
    @keyframes gradeffect {
        0% {
          background-position: top left;
        }
        100% {
          background-position: top right;
        }
    }
    .item10{
        background-color: #0066ad;
        position: relative;
        overflow: hidden;
        z-index: -7;
        @media (max-width:991px) {
            padding: 40px 0;
        }
        .bg1{
            position: absolute;
            background: url(../images/09_bg02.png) no-repeat center center / cover;
            width: 394px;
            height: 262px;
            top: 110px;
            left: -100px;
            @media (max-width:991px) {
                width: 180px;
                height: 130px;
                top: 55px;
                left: -50px;
                background-size: contain;
            }
        }
        .bg2{
            position: absolute;
            background: url(../images/09_bg01.png) no-repeat center center / cover;
            width: 307px;
            height: 307px;
            bottom: -50px;
            left: 80px;
            @media (max-width:991px) {
                width: 150px;
                height: 150px;
                bottom: -25px;
                left: 40px;
                background-size: contain;
            }
        }
        .container{
            margin-right: 0;
            margin-left: auto;
            padding-right: 0;
            @media (min-width:1199px) {
                max-width: 1495px;
            }
            @media (max-width:991px) {
                margin: 0 auto;
                padding-right: 25px;
            }
            .it10-bx{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                @media (max-width:991px) {
                    display: block;
                }
                .l{
                    width: 396px;
                    margin-right: 2%;
                    z-index:5;
                    @media (max-width:991px) {
                        width: 100%;
                    }
                    
                    .logo{
                        width: 149px;
                        height: 124px;
                        background: url(../images/logo.png) no-repeat center center / cover;
                        margin: 0 auto;
                        margin-bottom: 40px;
                    }
                    .btn-bx{
                        max-width: 297px;
                        width: 100%;
                        margin: 0 auto;
                        a{
                            display: block;
                            width: 100%;
                            height: 80px;
                            border-radius: 5px;
                            background-color: #ffffff;
                            text-align: center;
                            margin-bottom: 30px;
                            transition: .4s;
                            @media (max-width:991px) {
                                height: 70px;
                            }
                            @media (min-width:992px) {
                                &:hover{
                                    background-color: #c7e8ff;
                                }
                            }
                            span{
                                @include fontset(24,3.3,.6,700,#0066ad);
                                @media (max-width:991px) {
                                    font-size: pxToEm(18);
                                    line-height: 3.8;
                                }
                            }
                            &:first-child{
                                span{
                                    padding-left: 47px;
                                    position: relative;
                                    &::before{
                                        position: absolute;
                                        content: '';
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background: url(../images/09_icon01.png) no-repeat center center / contain;
                                        width: 28px;
                                        height: 32px;
                                    }
                                }
                            }
                            &:nth-child(2){
                                span{
                                    padding-left: 51px;
                                    position: relative;
                                    &::before{
                                        position: absolute;
                                        content: '';
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background: url(../images/09_icon02.png) no-repeat center center / contain;
                                        width: 32px;
                                        height: 32px;
                                    }
                                }
                            }
                            &:nth-child(3){
                                span{
                                    padding-left: 37px;
                                    position: relative;
                                    &::before{
                                        position: absolute;
                                        content: '';
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background: url(../images/09_icon03.png) no-repeat center center / contain;
                                        width: 16px;
                                        height: 32px;
                                    }
                                }
                            }
                        }
                    }
                    .adrss{
                        @include fontset(24,1.67,.6,700,#ffffff);
                        margin-bottom: 20px;
                        margin-top: 5px;
                        @media (max-width:991px) {
                            text-align: center;
                            font-size: pxToEm(16);
                        }
                    }
                    .map-btn{
                        display: block;
                        max-width: 297px;
                        width: 100%;
                        margin: 0 auto;
                        height: 80px;
                        border-radius: 5px;
                        background-color: #ffffff;
                        text-align: center;
                        margin-bottom: 30px;
                        @media (max-width:991px) {
                            height: 70px;
                        }
                        @media (min-width:992px) {
                            &:hover{
                                background-color: #c7e8ff;
                            }
                        }
                        span{
                            @include fontset(24,3.3,.6,700,#0066ad);
                            @media (max-width:991px) {
                                font-size: pxToEm(18);
                                line-height: 3.8;
                            }
                        }
                    }
                }
                .r{
                    width: calc(98% - 396px);
                    height: 750px;
                    z-index: 2;
                    position: relative;
                    @media (max-width:991px) {
                        width: 100%;
                        height: 400px;
                    }
                    iframe{
                        @media (max-width:991px) {
                            height: 400px;
                        }
                    }
                }
            }
        }
    }
}
.hiddendiv{
    display: none;
}