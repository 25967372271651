header{    
    //mmenu
    
    .navBox{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 990;
        min-width: 480px;
        background-color:rgba(#000000,0.85) ;
        height: 100vh;
        overflow:auto;
        display: none;
        @media (max-width:767px) {
            min-width: 100%;
        }
    }
    ul.mmenu{   
        width:100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);        
		> li{            
            text-align: center;
            a{
                position: relative;
                @include font-color(white,400,0em,1.2);
                @include font-size(pxToEm(16),pxToEm(24));
                display: inline-block;
                &:before{
                    content: ' ';
                    display: block;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    opacity: 0;
                    transition: opacity 0.6s;
                    background-color: #8cc9f3;
                }
            }
            @media (max-width: 767.98px){                
                a{
                    padding: 10px 0;
                    margin:5px 0;
                    display: block;
                }
            }
            @media (min-width: 768px){
                &:not(:last-child){
                    margin-bottom: 35px;
                }
                a:hover:before,
                &.active a:before{
                    opacity: 1;
                }
                a:hover{
                    color: #8cc9f3;
                }
            }    
        }
    }//end mmenu  
    .menuicon{
        position: fixed;
        right: 0;
        top: 0;
        z-index: 998;
        background-color: #0066ad;
        .mpsrel{
            @media (max-width: 575.98px){                
                width:50px;
                height: 50px;
            }
            @media (min-width: 576px){
                width:80px;
                height: 80px;
            }
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:50%;
            height: 2px;
            width: 32px;
            background: #ffffff;;
            transform: translateX(-50%);
            @media (max-width: 575.98px){
                width:24px;
            }
        }
        .bar{
            &:before{
                top:-12px;   
            }
            &:after{
                top:12px;
            }
        }
    }
    // //mobile meun open
    &.menu-expanded .menuicon{
        .bar{
            background:rgba(black,0);            
            &:before{
                top:0px;
                transform: translateX(-50%) rotate(-45deg);
            }
            &:after{
                top:0px;  
                transform: translateX(-50%) rotate(45deg);
            }
        }
    }
}
