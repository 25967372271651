.footer{
    z-index : -10;
    padding: 60px 0;
    background-color: #dceffb;
    @media (max-width:991px) {
        padding: 30px 0;
        padding-bottom: 70px;
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1015px;
        }
    }
    .f-bx{
        display: flex;
        justify-content: space-between;
        @media (max-width:991px) {
            display: block;
            text-align: center;
        }
        ul{
            li{
                @include fontset(24,1.67,.6,400,#004778);
                @media (max-width:991px) {
                    font-size: pxToEm(17);
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.showbar.f_info{
    opacity: 1;
}
.f_info{
    display: block;
    z-index: 9;
    opacity: 0;
    a:hover{
        text-decoration: none;
    }
    @media (max-width: 991px){
        .container {
            max-width: 100%;
            padding: 0 !important;
            }
    }
    
    
    @media (min-width:992px){
        position: absolute;
        bottom: 143px;
        height: 57px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        .infobtn{
            display: flex;
            justify-content: center;
            a{
                display: block;
                width: 25%;
                height: 57px;
                font-size: pxToEm(24);
                line-height: 2.4;
                background-color: #0066ad;
                text-align: center;
                color: #ffffff;
                border:1px solid #ffffff;
                transition: .4s;
                &:hover{
                    background-color: #fff;
                    color: #0066ad;
                }
            }
        }
    }
  
    @media (max-width:991px) {
        display: block;
        .infobtn{
            display: block;
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 99;
            display: flex;
            justify-content: space-between;
            a{
                height: auto;
                background-color: #0066ad;
                flex: 0 0 25%;
                text-align: center;
                padding: 18px 4px;
                font-size: pxToEm(15);
                color: #ffffff;
                font-weight: 400;
                text-decoration: none;
                border:1px solid #ffffff;
            }
        }
       
    }
}

