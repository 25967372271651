/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 25px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1230px;
    }
}

// top-menu
#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto 35px;
    }
    @media (min-width:768px) {
        margin:0 auto 50px;
    }
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 30px;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 30px;
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0.6px;
            border-bottom: 1px solid #dbdbdb;
            font-size: pxToEm(14);
            color: #000000;
            @media (max-width:767px) {
                padding:7px 0 3px;
                margin:0 20px 0 0; 
            }
            @media (min-width:768px) {
                margin:0 10px; 
                padding:7px 0;
            }
          
        }
        li.active a{
            border-color:black;
            color:black;
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:50%;
        z-index: 10;
        background-color: #000000;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        // background: url(../svg/slick-next.svg) no-repeat center / 15px;
        right: -18px;
    }

    a.flex-prev{
        // background: url(../svg/slick-prev.svg) no-repeat center / 15px;
        left:  -18px;
    }
    }
    .open_flexslider{
    .flex-direction-nav{
        display: block;
    }
}
.ball{
    border-radius: 50%;
}
@keyframes fade{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.b-blueline{
   text-align: center;
    .ti{
        display: inline-block;
        position: relative;
        @include fontset(50,1,0,700,#0066ad);
        z-index: 3;
        @media (max-width:991px) {
            font-size: pxToEm(25);
        }
        &::before{
            position: absolute;
            content: '';
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 16px;
            background-color: #8cc9f3;
            z-index: -1;
            opacity: 0.6;
            @media (max-width:991px) {
                height: 10px;
            }
        }
    }
}

.in_fade{
    opacity: 0;
    transition: .7s cubic-bezier(0.5, 1, 0.89, 1);
    top: 25px;
    position: relative;
}
.in_fade.active{
    opacity: 1;
    top: 0;
}